<template>
    <div size="A4" class="page">
        <div v-if="loading">
            <Loading />
        </div>
        <!-- <b-overlay :show="loading"> -->
        <template v-else>
            <div class="text-right">
                <b-button @click="pdfExport" variant="primary" class="mb-3" size="sm">
                    <i class="ri-printer-fill"></i>
                    {{ $t('teaGardenConfig.Print') }}
                </b-button>
            </div>
            <div style="border: 1px double #222; padding: 1rem; height: 100%">
                <!-- <TeaGardenReportHead :baseUrl="teaGardenServiceBaseUrl" :uri="'/configuration/report-heading/detail'" :orgId="5" :loadingState="false">
                    {{ $t('teaGardenService.small_tea_farmer_certificate_title') + ' ' + $t('globalTrans.certificate') }}
                </TeaGardenReportHead> -->
                <div class="text-center" style="text-decoration: underline;">{{ $t('lrcpnServicePanel.formalin_clearance_application') }} {{ $t('lrcpnServicePanel.certificate') }}</div>
                <br>
                <div class="mb-4" style="display: flex; flex-wrap: wrap;">
                    <div class="" style="width:50%"><b>{{ $t('globalTrans.application_id') }}</b> : {{ appDetail.application_id ? EngBangNum(appDetail.application_id) : '' }}</div>
                    <div class="text-right" style="width:50%"><b>{{ $t('globalTrans.date') }}</b> : {{ appDetail.approved_date | dateFormat }}</div>
                </div>
                <div class="mb-3">
                    <p>
                        {{ currentLocale === 'bn' ? "এতদ্দ্বারা ফরমালিন নিয়ন্ত্রণ আইন, ২০১৫ (২০১৫ সনের ৫নং আইন), ফরমালিন (আমদানি, উৎপাদন, পরিবহন, মজুদ, বিক্রয় ও ব্যবহার) নিয়ন্ত্রণ বিধিমালা, ২০১৫ এর বিধানাবলী এবং এই লাইসেন্সে উল্লিখিত শর্তাবলী সাপেক্ষে নিম্নবর্ণিত ব্যক্তি/প্রতিষ্ঠানকে ফরমালিন আমদানির জন্য লাইসেন্স প্রদান করা হইল।"
                        : "This is to certify that the following individual/institution is granted a license to import formalin subject to the provisions of the Formalin Control Act, 2015 (Act No. 5 of 2015), the Formalin (Import, Production, Transportation, Storage, Sale, and Use) Control Rules, 2015, and the conditions specified in this license." }}
                    </p>
                    <p>
                        {{ currentLocale === 'en'
                        ? 'This license will remain valid until ' +  EngBangNum(appDetail.expire_date)
                        : 'এই লাইসেন্স ' + EngBangNum(appDetail.expire_date) + ' তারিখ পর্যন্ত বলবৎ থাকিবে।' }}
                    </p>
                </div>
                <table class="report-info">
                    <tbody>
                        <tr>
                            <td style="width: 3%;">{{ $n(1) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.name_institution') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;"> {{ currentLocale === 'en' ? appDetail.applicant_name_en : appDetail.applicant_name_bn }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(2) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 40%; vertical-align: top;">{{ currentLocale === 'en' ? ' (A) ' : ' (ক) ' }}{{ $t('lrcpnServicePanel.name_owner') }}</td>
                            <td style="width: 5%; vertical-align: top;">&emsp;:&nbsp;</td>
                            <td style="width: 50%; vertical-align: top;" v-if="appDetail.applicant_nid"> {{ currentLocale === 'en' ? appDetail.applicant_name_en : appDetail.applicant_name_bn }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;"></td>
                            <td style="width: 2%"></td>
                            <td style="width: 40%; vertical-align: top;">{{ currentLocale === 'en' ? ' (B) ' : ' (খ) ' }}{{ $t('lrcpnServicePanel.name_owner_nid') }}</td>
                            <td style="width: 5%; vertical-align: top;">&emsp;:&nbsp;</td>
                            <td style="width: 50%; vertical-align: top;" v-if="appDetail.applicant_nid">{{  EngBangNum(appDetail.applicant_nid) }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(3) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 40%; vertical-align: top;">{{ currentLocale === 'en' ? ' (A) ' : ' (ক) ' }}{{ $t('lrcpnServicePanel.office') }}</td>
                            <td style="width: 5%; vertical-align: top;">&emsp;:&nbsp;</td>
                            <td style="width: 50%; vertical-align: top;" v-if="appDetail.business_info.address_en"> {{ currentLocale === 'en' ? appDetail.business_info.address_en : appDetail.business_info.address_bn }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;"></td>
                            <td style="width: 2%"></td>
                            <td style="width: 40%; vertical-align: top;">{{ currentLocale === 'en' ? ' (B) ' : ' (খ) ' }}{{ $t('lrcpnServicePanel.business_place') }}</td>
                            <td style="width: 5%; vertical-align: top;">&emsp;:&nbsp;</td>
                            <td style="width: 50%; vertical-align: top;" v-if="appDetail.business_info.address_en">{{ currentLocale === 'en' ? appDetail.business_info.address_en : appDetail.business_info.address_bn }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(4) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ currentLocale === 'en' ? ' (A) ' : ' (ক) ' }}{{ $t('lrcpnServicePanel.drug_no') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;">{{ appDetail.business_info?.trade_license_info?.license_no ? EngBangNum(appDetail.business_info?.trade_license_info.license_no) : $t('lrcpnServicePanel.n/a') }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%;"></td>
                            <td style="width: 2%"></td>
                            <td style="width: 40%;">{{ currentLocale === 'en' ? '(B) ' : '(খ) ' }}{{ $t('lrcpnServicePanel.drug_license_authority') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;">{{ appDetail.business_info?.drug_license_info?.license_provider ? (appDetail.business_info?.drug_license_info?.license_provider) : $t('lrcpnServicePanel.n/a')}}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(5) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 40%; vertical-align: top;">{{ $t('lrcpnServicePanel.formalin_details') }}</td>
                            <td style="width: 5%; vertical-align: top;">&emsp;:&nbsp;</td>
                            <td  style="width: 50%;" v-if="appDetail.business_info?.import_amounts_info">
                                <b-table-simple style="width: 100%" bordered>
                                    <b-tr>
                                        <b-th class="text-center" style="width: 50%;"> {{ $t('lrcpnServicePanel.row_material_name') }}</b-th>
                                        <b-th class="text-center" style="width: 25%;"> {{ $t('lrcpnServicePanel.qty') }}</b-th>
                                        <b-th class="text-center" style="width: 25%;"> {{ $t('lrcpnServicePanel.price') }}</b-th>
                                    </b-tr>
                                    <template v-if="appDetail.business_info.import_amounts_info?.length">
                                        <slot v-for="(item, index) in appDetail.business_info?.import_amounts_info">
                                            <b-tr :key="index">
                                                <b-td class="text-center">
                                                    {{ currentLocale === 'en' ? item.row_material_name_en : item.row_material_name_bn }}
                                                </b-td>
                                                <b-td class="text-center">
                                                    {{ $n(item.qty, {useGrouping: false}) }}
                                                </b-td>
                                                <b-td class="text-center">
                                                    {{ $n(item.price, {useGrouping: false}) }}
                                                </b-td>
                                            </b-tr>
                                        </slot>
                                        <b-tr>
                                            <b-td colspan="2" class="text-right">{{$t('globalTrans.total')}}</b-td>
                                            <b-td class="text-center">{{ $n(totalAmount) }}</b-td>
                                        </b-tr>
                                    </template>
                                    <template v-else>
                                        <b-tr>
                                            <b-td colspan="3" class="text-center">{{ $t('national_award.no_data_added') }}</b-td>
                                        </b-tr>
                                    </template>
                                </b-table-simple>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(6) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.place_imported') }}</td>
                            <td style="width: 5%;"></td>
                            <td style="width: 50%;"></td>
                        </tr>
                        <tr>
                            <td style="width: 3%;"></td>
                            <td style="width: 2%"></td>
                            <td style="width: 40%;">{{ currentLocale === 'en' ? '(A) ' : '(ক) ' }}{{ $t('lrcpnServicePanel.warehouse_address') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.warehouse_details?.warehouse_address_en">{{ currentLocale === 'en' ? appDetail.business_info?.warehouse_details?.warehouse_address_en : appDetail.business_info?.warehouse_details?.warehouse_address_bn }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%;"></td>
                            <td style="width: 2%"></td>
                            <td style="width: 40%;">{{ currentLocale === 'en' ? '(B) ' : '(খ) ' }}{{ $t('lrcpnServicePanel.warehouse_details') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.warehouse_details?.warehouse_details">{{ appDetail.business_info?.warehouse_details?.warehouse_details }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%;"></td>
                            <td style="width: 2%"></td>
                            <td style="width: 40%;">{{ currentLocale === 'en' ? '(C) ' : '(গ) ' }}{{ $t('lrcpnServicePanel.warehouse_capacity') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;">{{ appDetail.business_info?.warehouse_details?.warehouse_cpacity ? EngBangNum(appDetail.business_info?.warehouse_details?.warehouse_cpacity) : '' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%;"></td>
                            <td style="width: 2%"></td>
                            <td style="width: 40%;">{{ currentLocale === 'en' ? '(D) ' : '(ঘ) ' }}{{ $t('lrcpnServicePanel.warehouse_location') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.warehouse_details?.warehouse_location">{{ appDetail.business_info?.warehouse_details?.warehouse_location }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(7) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.purpose_formalin') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.present_import_details?.import_purpose"> {{ appDetail.business_info?.present_import_details?.import_purpose }} </td>
                        </tr>
                    </tbody>
                </table>
                <strong style="margin-top:1rem; display: block;">{{ $t('teaGardenPanel.conditions') }} :</strong>
                <!-- <div class="text-justify" v-html="currentLocale === 'en' ? application.application.service_instruction.instruction_en : application.application.service_instruction.instruction_bn"></div> -->
                <table style="width:100%; margin-top: 1rem;">
                    <tbody>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(1) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'This permit shall not be transferable.' : 'এই ছাড়পত্র হস্তান্তরযোগ্য হইবে না।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(2) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'The formalin imported under this license may not be used in any manner other than as stipulated by the Formalin Control Act, 2015 (Act No. 5 of 2015), the Formalin (Import, Production, Transportation, Storage, Sale, and Use) Control Rules, 2015, and the conditions of this license.' : 'এই লাইসেন্সের অধীন আমদানিকৃত ফরমালিন, ফরমালিন নিয়ন্ত্রণ আইন ২০১৫ (২০১৫ সনের ৫নং আইন), ফরমালিন (আমদানি, উৎপাদন, পরিবহন, মজুদ, বিক্রয় ও ব্যবহার) নিয়ন্ত্রণ বিধিমালা, ২০১৫ এবং এই লাইসেন্সের শর্তাবলী ব্যতীত অন্য কোনভাবে ব্যবহার করা যাইবে না।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(3) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? "To import or sell any formalin under this license, it is mandatory to comply with the conditions specified in this license as well as the government's foreign exchange policies, import policies, and the relevant customs, excise, and VAT regulations." : 'এই লাইসেন্সের আওতায় কোন ফরমালিন আমদানি বা বিক্রয় করিতে হইলে এই লাইসেন্সে বর্ণিত শর্তাবলী ছাড়াও সরকারের বৈদেশিক মুদ্রা সংক্রান্ত নীতিমালা, আমদানি নীতিমালা এবং শুল্ক, আবগারী ও ভ্যাট সংক্রান্ত নিয়ম-কানুনসমূহ যথাযথভাবে অনুসরণ করিতে হইবে।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(4) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'Business operations and storage of imported formalin under this license are not permitted at any location other than the one specified in this license.' : 'এই লাইসেন্সে উল্লিখিত স্থান ব্যতিরেকে অন্য কোথাও এই লাইসেন্সের আওতায় ব্যবসা পরিচালনা এবং আমদানিতব্য ফরমালিন মজুদ রাখা যাইবে না।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(5) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'All activities related to the import and purchase-sale of formalin under this license must be conducted in compliance with the applicable laws of both countries, involving legitimate institutions or individuals from within the country and abroad.' : 'দেশের এবং বিদেশের বৈধ প্রতিষ্ঠান বা ব্যক্তির সঙ্গে উভয় দেশের প্রচলিত আইনের বিধান পালন করত: এই লাইসেন্সের আওতায় ফরমালিন আমদানি ও ক্রয়-বিক্রয় সংক্রান্ত যাবতীয় কার্য সম্পাদন করিতে হইবে।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(6) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'All imported formalin must be stored and maintained with appropriate security measures.' : 'উপযুক্ত নিরাপত্তা ব্যবস্থা গ্রহণ করত: আমদানিতব্য যাবতীয় ফরমালিন সংরক্ষণ ও মজুদ করিতে হইবে।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(7) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'According to the Formalin Control Act, 2015 (Act No. 5 of 2015), and the Formalin (Import, Production, Transportation, Storage, Sale, and Use) Control Rules, 2015, the licensed institution must provide full cooperation to the authorized officer/person or government-appointed officials at any time for the inspection and examination of the premises, all facilities therein, stored formalin, registers, accounts, etc.' : 'ফরমালিন নিয়ন্ত্রণ আইন, ২০১৫ (২০১৫ সনের ৫নং আইন), ফরমালিন (আমদানি, উৎপাদন, পরিবহন, মজুদ, বিক্রয় ও ব্যবহার) নিয়ন্ত্রণ বিধিমালা, ২০১৫ অনুযায়ী ক্ষমতাপ্রাপ্ত কর্মকর্তা/ব্যক্তি অথবা সরকার মনোনীত কর্মকর্তাকে যে কোন সময় লাইসেন্সকৃত প্রতিষ্ঠানের প্রাঙ্গণ, তদস্থিত যাবতীয় স্থাপনা, মজুদ ফরমালিন, রেজিস্টারসমূহ, হিসাব-পত্র, ইত্যাদি পরিদর্শন ও পরীক্ষায় সকল প্রকার সহযোগিতা প্রদান করিতে হইবে।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(8) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? "A written report must be submitted to the government or the government-appointed official after each import of formalin. No formalin packaging shall be opened or any content removed without the personal presence and inspection of the said official." : 'প্রত্যেকবার ফরমালিন আমদানির পর সরকার/সরকার মনোনীত কর্মকর্তাকে লিখিতভাবে রিপোর্ট করিতে হইবে এবং উক্ত কর্মকর্তার ব্যক্তিগত উপস্থিতি ও পরীক্ষা-নিরীক্ষা ছাড়া কোন ফরমালিনের মোড়ক খোলা বা উহা হইতে কোন কিছু অপসারণ করা যাইবে না।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(9) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'The imported formalin may not be sold or transferred to anyone other than the licensed person.' : 'লাইসেন্স প্রাপ্ত ব্যক্তি ছাড়া অন্য কাহারও নিকট আমদানিকৃত ফরমালিন বিক্রয় বা উহা কোনরূপ হস্তান্তর করা যাইবে না।' }}</td>
                        </tr><tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(10) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'The licensee must maintain regular records of all imported formalin in the prescribed format.' : 'লাইসেন্সধারীকে, নির্ধারিত ফরমে আমদানিকৃত সকল ফরমালিনের হিসাব নিয়মিত সংরক্ষণ করিতে হইবে।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(11) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'If any information provided at the time of obtaining the license is later found to be incorrect, or if there is a violation of the Formalin Control Act, 2015 (Act No. 5 of 2015), the Formalin (Import, Production, Transportation, Storage, Sale, and Use) Control Rules, 2015, or the terms and conditions of this license, the licensing authority reserves the right to cancel, revoke, or temporarily suspend the license.' : 'লাইসেন্স গ্রহণকালে পরিবেশিত কোন তথ্য পরবর্তীতে ভুল প্রমাণিত হইলে কিংবা ফরমালিন নিয়ন্ত্রণ আইন, ২০১৫ (২০১৫ সনের ৫ নং আইন), ফরমালিন (আমদানি, উৎপাদন, পরিবহন, মজুদ, বিক্রয় ও ব্যবহার) নিয়ন্ত্রণ বিধিমালা, ২০১৫ এবং এই লাইসেন্সের শর্তাবলী অমান্য করা হইলে লাইসেন্স প্রদানকারী কর্তৃপক্ষ উক্ত লাইসেন্স বাতিল, প্রত্যাহার বা সাময়িকভাবে স্থগিত করিতে পারিবে।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(12) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'If any provision under Clause 11 is violated, no appeal or application for review shall be admissible to any court against the provisions of the Formalin Control Act, 2015 (Act No. 5 of 2015), or the Formalin (Import, Production, Transportation, Storage, Sale, and Use) Control Rules, 2015, as applicable.' : 'শর্ত ১১ এর অধীন কোন ব্যবস্থা গ্রহণ করা হইলে উহার বিরুদ্ধে ফরমালিন নিয়ন্ত্রণ আইন,  ২০১৫ (২০১৫ সনের ৫ নং আইন) বা ফরমালিন (আমদানি, উৎপাদন, পরিবহন, মজুদ, বিক্রয় ও ব্যবহার) নিয়ন্ত্রণ বিধিমালা, ২০১৫ এর অধীন ক্ষেত্রমত, আপীল বা পুনর্বিবেচনার আবেদন ব্যতীত কোন আদালতের শরণাপন্ন হওয়া যাইবে না।' }}</td>
                        </tr>
                    </tbody>
                </table>
                <!-- aproved info -->
                <table style="margin-top: 3rem;">
                    <tr>
                        <td style="width: 45%; text-align:justify; vertical-align: top;">
                            {{ $t('globalTrans.date') }} : <span style="text-decoration-style: dotted; text-decoration-line: underline;">
                                {{ appDetail.approved_date | dateFormat }}
                            </span>
                        </td>
                        <td style="width: 20%; text-align: center; padding-right: 10px; padding-left: 10px; vertical-align: top;">
                        </td>
                        <td style="width: 35%; margin-left: 10px; padding-left: 10px; vertical-align: top;">
                            <div>
                                {{ $t('lrcpnServicePanel.seal_authority') }} <br><br>
                                <p v-if="appDetail.approver_name">{{ $t('globalTrans.name') }} :
                                    <span style="text-decoration-style: dotted; text-decoration-line: underline;">{{ currentLocale === 'en' ? appDetail.approver_name : appDetail.approver_name_bn }}</span>
                                </p>
                                <p v-if="appDetail.designation_id">{{ $t('globalTrans.designation') }} :
                                   {{ getDesignationName(appDetail.designation_id) }}
                                </p>
                                <p v-if="appDetail.office_id">{{ $t('globalTrans.office') }} :
                                   {{ getOfficeName(appDetail.office_id) }}
                                </p>
                                <!-- <p>{{ $t('globalTrans.date') }} : <span
                                    style="text-decoration-style: dotted; text-decoration-line: underline;">{{ appDetail.approved_date | dateFormat }}</span>
                                </p> -->
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="text-left mt-3">
                <b-button @click="back" class="ml-3"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
            </div>
        </template>
        <!-- </b-overlay> -->
    </div>
</template>
<script>
  import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
  import { formalinImportApplicationCertificateApi } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  import Loading from './loading/Details.vue'
  export default {
    mixins: [ModalBaseMasterList],
    components: {
      Loading
    },
    data () {
      return {
        lrcpnServiceBaseUrl: lrcpnServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          company_name: '',
          limit: 10
        },
        items: [],
        profile: this.$store.state.ExternalUserService.eBizPanel.associationProfile,
        associationInfoList: [],
        warehouseList: [],
        viewitemId: 0
      }
    },
    created () {
      this.loadData()
    },
    watch: {
    },
    computed: {
        loading: function () {
            return this.$store.state.commonObj.loading
        },
        currentLocale () {
          return this.$i18n.locale
        },
        formattedDate () {
          return this.appDetail.created_at.slice(0, 10)
        },
        typeOfBusinessList () {
          return this.$store.state.ExternalUserService.eBizPanel.commonObj.typeOfBusinessList.filter(item => item.status === 1)
        },
        appId () {
          return this.$route.params.id
        },
        masterAcidNameList () {
          if (this.$store.state.Auth.authUser.user_type === 2) {
            return this.$store.state.ExternalUserService.lrcpnPanel.commonObj.masterAcidNameList.filter(item => item.status === 1)
          } else {
            return this.$store.state.LrcpnService.commonObj.masterAcidNameList.filter(item => item.status === 1)
          }
        },
        totalAmount () {
            let total = 0
            this.appDetail.business_info.import_amounts_info.forEach(item => {
                total += item.price
            })
            return total
        },
        divisionList () {
            return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
        },
        districtList () {
            return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1)
        },
        cityCorporationList () {
            return this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1)
        },
        upazilaList () {
            return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1)
        },
        municipalityList () {
            return this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.status === 1)
        },
        unionList () {
            return this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1)
        },
        officeList () {
            return this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1)
        },
        designationList () {
            return this.$store.state.CommonService.commonObj.designationList.filter(item => item.status === 1)
        },
        areaTypeList () {
            return this.$store.state.commonObj.areaTypeList
        }
      },
    methods: {
        getTypeOfBussiness (businessId) {
              const cateObj = this.$store.state.ExternalUserService.eBizPanel.commonObj.typeOfBusinessList.filter(item => item.status === 1).find(item => item.value === businessId)
              if (cateObj !== undefined) {
                  if (this.$i18n.locale === 'bn') {
                      return cateObj.text_bn
                  } else {
                      return cateObj.text_en
                  }
              }
        },
        EngBangNum (n) {
              if (this.$i18n.locale === 'bn') {
                  return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
              } else {
                  return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
              }
          },
      searchData () {
        this.loadData()
      },
      async loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const result = await RestApi.getData(lrcpnServiceBaseUrl, formalinImportApplicationCertificateApi + '/' + this.appId)
        if (result.success) {
          this.appDetail = result.data
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      },
  async pdfExport () {
    this.loading = true
    const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 1, app_id: this.appId })
    this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
    const result = await RestApi.getPdfData(lrcpnServiceBaseUrl, formalinImportApplicationCertificateApi + '/' + this.appId, params)
    var blob = new Blob([result], {
      type: 'application/pdf'
    })
    this.loading = false
    var url = window.URL.createObjectURL(blob)
    window.open(url).print()
    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
  },
      getAcidName (id) {
          const obj = this.masterAcidNameList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getOfficeName (id) {
          const obj = this.officeList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getDesignationName (id) {
          const obj = this.designationList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getAreaTypeList (id) {
          const obj = this.areaTypeList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getDivisionName (id) {
          const obj = this.divisionList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getDistrictList (id) {
          const obj = this.districtList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getCityCorporationList (id) {
          const obj = this.cityCorporationList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getUpazilaList (id) {
          const obj = this.upazilaList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getMunicipalityList (id) {
          const obj = this.municipalityList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getUnionList (id) {
          const obj = this.unionList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      back () {
        this.$router.go(-1)
      }
    }
  }
</script>
<style scoped>
.page {
    background: white;
    padding: .5in;
    margin: 0 auto;
    margin-bottom: 0.5cm;
}

.page[size="A4"] {
    width: 21cm;
    min-height: 29.7cm;
    height: auto;
}

.report-table {
    margin-top: 1rem;
    width: 100%;
}

.report-table td,
.report-table th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 4px;
}

@media print {

    body,
    .page {
        margin: 0;
        box-shadow: 0;
    }
}
</style>
